import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm, Controller } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "react-flexbox-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { green } from "@material-ui/core/colors";
import DoneAllSharpIcon from "@material-ui/icons/DoneAllSharp";

import FloatingActionButton from "../components/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  title: {
    paddingBottom: 20,
    paddingTop: 20,
    color: "#374047",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  spacing: {
    paddingRight: 10,
  },
}));

/*const ButtonC = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFFEEE",
    color: "#EF7E25",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#EF7E25",
    },
  },
}))(Button);*/

const ButtonP = withStyles((theme) => ({
  root: {
    /*[theme.breakpoints.down("xs")]: {
      color: "#FFFEEE",
      backgroundColor: "#404040",
    },
    color: "#EF7E25",
    backgroundColor: "#FFFEEE",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#EF7E25",
    },*/
    color: "#FFFEEE",
    backgroundColor: "#EF7E25",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#404040",
    },
  },
}))(Button);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#EF7E25",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#EF7E25",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#EF7E25",
      },
    },
  },
})(TextField);

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";

const defaultValues = {
  nombre: "",
  correo: "",
  telefono: 99999999,
  asunto: "",
  comentario: "",
};

const ContactUs = () => {
  const classes = useStyles();
  const { handleSubmit, reset, setValue, control, pristine, submitting } =
    useForm({
      defaultValues,
    });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const view = 2;
  const [number, setNumber] = useState("");

  const loadNumber = useCallback(async () => {
    // cargar número de whatsapp de esta view
    const resp = await axios.get(`${apiBaseUrl}/Number/${view}`);
    const number = resp.data[0].telefono;
    setNumber(number);
  }, []);

  //Carga las tiendas al inicializar
  useEffect(() => {
    loadNumber();
  }, [loadNumber]);

  const handleOpen = (nombre) => {
    setMessage(nombre);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    // e.preventDefault();

    let datos = {
      nombre: data.nombre,
      telefono: data.telefono,
      correo: data.correo,
      asunto: data.asunto,
      comentario: data.comentario,
    };

    axios
      .post(`${apiBaseUrl}/Contact`, datos)
      .then((response) => {
        setValue("nombre", "");
        setValue("correo", "");
        setValue("telefono", "99999999");
        setValue("asunto", "");
        setValue("comentario", "");
        handleOpen(data.nombre);
      })
      .catch((e) => {});
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Contactanos
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>

      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="nombre"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Nombre"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "Se requiere su nombre" }}
        />
        <Controller
          name="telefono"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Teléfono"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type="number"
            />
          )}
          rules={{
            required: "Se requiere su número de teléfono.",
            maxLength: 11,
            minLength: 8,
          }}
        />
        <Controller
          name="correo"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Correo Electrónico"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type="email"
            />
          )}
          rules={{
            required: "Correo electrónico es requerido.",
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          }}
        />
        <Controller
          name="asunto"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Asunto"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "El asunto es requerido." }}
        />
        <Controller
          name="comentario"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Comentario"
              variant="filled"
              value={value}
              onChange={onChange}
              multiline
              rows="3"
              rowsMax="4"
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "El comentario es requerido." }}
        />
        <div>
          <ButtonP
            variant="contained"
            type="button"
            disabled={pristine || submitting}
            onClick={() =>
              reset({
                nombre: "",
                correo: "",
                telefono: 99999999,
                asunto: "",
                comentario: "",
              })
            }
          >
            Cancelar
          </ButtonP>
          <ButtonP
            type="submit"
            disabled={pristine || submitting}
            variant="contained"
            color="primary"
          >
            Enviar
          </ButtonP>
        </div>
      </form>

      <FloatingActionButton phone={number} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              <DoneAllSharpIcon
                style={{ color: green[500], fontSize: 40 }}
                className={classes.spacing}
              />
              Correo enviado con éxito
            </h2>
            <div id="transition-modal-description">
              <Row>
                <Col xs={12}>
                  <Row center="xs">
                    <Col xs={10}>Gracias por contactarnos {message}</Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ContactUs;
