import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    paddingTop: 20,
  },
}));

export default function Home() {
  const classes = useStyles();

  const history = useHistory();

  const rutaServ = ""; //Producción

  function storeLink() {
    history.push(`${rutaServ}/store`);
  }

  function contactUsLink() {
    history.push(`${rutaServ}/contactUs`);
  }

  function vimvLink() {
    history.push(`${rutaServ}/vimv`);
  }

  function historyLink() {
    history.push(`${rutaServ}/history`);
  }

  function questionsLink() {
    history.push(`${rutaServ}/questions`);
  }

  function politicsLink() {
    history.push(`${rutaServ}/politics`);
  }

  function wholesalersLink() {
    history.push(`${rutaServ}/wholesalers`);
  }

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <Typography variant="h3">Inicio</Typography>
        <List component="nav" aria-label="secondary mailbox folder">
          <ListItem button onClick={storeLink}>
            <ListItemText primary="Nuestras tiendas" />
          </ListItem>
          <ListItem button onClick={contactUsLink}>
            <ListItemText primary="Contactanos" />
          </ListItem>
          <ListItem button onClick={vimvLink}>
            <ListItemText primary="Misión y Visión" />
          </ListItem>
          <ListItem button onClick={historyLink}>
            <ListItemText primary="Historia de la empresa." />
          </ListItem>
          <ListItem button onClick={questionsLink}>
            <ListItemText primary="Preguntas frecuentes" />
          </ListItem>
          <ListItem button onClick={politicsLink}>
            <ListItemText primary="Politica de cambio" />
          </ListItem>
          <ListItem button onClick={wholesalersLink}>
            <ListItemText primary="Quiero ser mayorista" />
          </ListItem>
        </List>
      </div>
    </div>
  );
}
