import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import MapContainer from "./MapContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 3),
    width: "70%",
    height: "80%",
  },
}));

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

function ModalGoogle({ showModal, setModal, closeModal, idStore }) {
  const classes = useStyles();

  const mapData = {
    issInfo: {
      id: 0,
      name: "",
      lat: 0,
      lng: 0,
    },
    error: false,
  };

  const [trackerState, setTrackerState] = useState(mapData);

  const loadStore = (id) => {
    axios
      .get(`${apiBaseUrl}/Stores/${id}`)
      .then((response) => {
        setTrackerState({
          issInfo: {
            lat: response.data[0].latitud,
            lng: response.data[0].longitud,
            name: response.data[0].nombre,
          },
          error: false,
        });
      })
      .catch((e) => {
        setTrackerState({
          issInfo: {
            id: 0,
            lat: 0,
            lng: 0,
          },
          error: false,
        });
      });
  };

  // //Carga las tiendas al inicializar
  useEffect(() => {
    setTimeout(() => {
      loadStore(idStore);
    }, 100);
  }, [idStore]);

  return (
    <>
      {showModal ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showModal}
          onClose={closeModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className={classes.paper}>
              <MapContainer
                latitud={trackerState.issInfo.lat}
                longitud={trackerState.issInfo.lng}
                name={trackerState.issInfo.name}
              />
            </div>
          </Fade>
        </Modal>
      ) : null}
    </>
  );
}

export default ModalGoogle;
