import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "5%",
    right: "3%",
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

function FloatingActionButton(props) {
  const classes = useStyles();

  return (
    <>
      <Zoom in={true} timeout={{ enter: 500, exit: 500 }} unmountOnExit>
        <Fab
          aria-label="WhatsApp"
          className={classes.fab}
          color="inherit"
          href={`https://wa.me/504${props.phone}`}
        >
          <WhatsAppIcon fontSize="large" />
        </Fab>
      </Zoom>
    </>
  );
}

export default FloatingActionButton;
