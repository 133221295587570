import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "react-flexbox-grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FloatingActionButton from "../components/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  title: {
    paddingBottom: 20,
    paddingTop: 20,
    color: "#374047",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#374047",
  },
}));

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

function Questions() {
  const classes = useStyles();

  const [questions, setQuestions] = useState([]);

  const view = 5;
  const [number, setNumber] = useState("");

  const loadQuestion = useCallback(async () => {
    const respose = await axios.get(`${apiBaseUrl}/Questions`);
    const questions = respose.data;
    setQuestions(questions);

    // cargar número de whatsapp de esta view
    const resp = await axios.get(`${apiBaseUrl}/Number/${view}`);
    const number = resp.data[0].telefono;
    setNumber(number);
  }, []);

  //Carga la historia al inicializar
  useEffect(() => {
    loadQuestion();
  }, [loadQuestion]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Preguntas frecuentes
              </Typography>
              <Typography
                component={"div"}
                variant={"body1"}
                gutterBottom
                align="justify"
              >
                {questions.map((question) => (
                  <Accordion key={question.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        {question.pregunta}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        {question.respuesta}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>

      <FloatingActionButton phone={number} />
    </>
  );
}

export default Questions;
