import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import HocAux from "../Hoc/HocAux";

const mapStyles = {
  width: "70%",
  height: "80%",
};

const MapContainer = (props) => {
  let iconMarker = new window.google.maps.MarkerImage(
    "https://img.icons8.com/color/452/google-maps-new.png",
    null,
    null,
    null,
    new window.google.maps.Size(32, 32)
  );

  let mapTrack =
    props.latitud !== "" ? (
      <Map
        google={props.google}
        zoom={10}
        style={mapStyles}
        initialCenter={{ lat: props.latitud, lng: props.longitud }}
      >
        <Marker
          name={props.name}
          position={{ lat: props.latitud, lng: props.longitud }}
          icon={iconMarker}
        />
      </Map>
    ) : null;

  return <HocAux>{mapTrack}</HocAux>;
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAQ6Qte86DjSVXXFmGmaSUQ8lgG2DLpeXk",
})(MapContainer);
