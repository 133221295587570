import React, { useEffect, useState, useCallback } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "react-flexbox-grid";
import axios from "axios";
import ModalGoogle from "../components/ModalGoogle";
import ModalSchedules from "../components/ModalSchedules";

import FloatingActionButton from "../components/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  title: {
    paddingBottom: 20,
    paddingTop: 20,
    color: "#374047",
  },
  text: {
    color: "#374047",
  },
  card: {
    marginTop: theme.spacing(2),
    margin: "auto",
    flexDirection: "column",
    maxWidth: 370,
    marginBottom: 20,
    boxShadow: theme.shadows[6],
    "&:hover": {
      boxShadow: theme.shadows[24],
    },
  },
}));

const ButtonLink = withStyles((theme) => ({
  root: {
    /*color: "#EF7E25",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#EF7E25",
    },*/
    color: "#FFFEEE",
    backgroundColor: "#EF7E25",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#404040",
    },
  },
}))(Button);

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

export default function Store() {
  const classes = useStyles();

  const [stores, setStores] = useState([]);

  const view = 1;
  const [number, setNumber] = useState("");

  const loadStores = useCallback(async () => {
    const respose = await axios.get(`${apiBaseUrl}/Stores`);
    const stores = respose.data;
    setStores(stores);

    // cargar número de whatsapp de esta view
    const resp = await axios.get(`${apiBaseUrl}/Number/${view}`);
    const number = resp.data[0].telefono;
    setNumber(number);
  }, []);

  //Carga las tiendas al inicializar
  useEffect(() => {
    loadStores();
  }, [loadStores]);

  // modal maps
  const [open, setOpen] = useState(false);
  const [idStore, setIdStore] = useState();

  const openModal = (id) => {
    setIdStore(id);
    setOpen((prev) => !prev);
  };

  const closeModal = () => {
    setOpen(false);
  };

  // modal horarios
  const [openH, setOpenH] = useState(false);
  const [idStoreH, setIdStoreH] = useState();

  const openModalH = (id) => {
    setIdStoreH(id);
    setOpenH((prev) => !prev);
  };

  const closeModalH = () => {
    setOpenH(false);
  };

  return (
    <div className={classes.root}>
      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Nuestras tiendas
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Row center="xs">
            {stores.map((store) => (
              <Col key={store.id} xs={10} sm={8} md={6} lg={4} xl={4}>
                <Card className={classes.card}>
                  <CardActionArea onClick={(e) => openModal(store.id, e)}>
                    <CardMedia
                      component="img"
                      alt={store.nombre}
                      height="150"
                      image={`${process.env.PUBLIC_URL}/docs/${store.imagen}`}
                      title={store.nombre}
                    />
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={classes.text}
                      >
                        {store.nombre}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h3"
                        className={classes.text}
                      >
                        {store.ciudad}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {store.ubicacion}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <ButtonLink
                      size="small"
                      color="primary"
                      href={"https://wa.me/504" + store.telefono}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tel: +504 {store.telefono}
                    </ButtonLink>
                    <ButtonLink
                      size="small"
                      color="primary"
                      onClick={(e) => openModalH(store.id, e)}
                    >
                      Horarios
                    </ButtonLink>
                  </CardActions>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <ModalGoogle
        showModal={open}
        setShowModal={setOpen}
        closeModal={closeModal}
        idStore={idStore}
      />

      <ModalSchedules
        showModal={openH}
        setShowModal={setOpenH}
        closeModal={closeModalH}
        idStore={idStoreH}
      />

      <FloatingActionButton phone={number} />
    </div>
  );
}
