import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "react-flexbox-grid";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import FloatingActionButton from "../components/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  title: {
    paddingBottom: 20,
    paddingTop: 20,
    color: "#374047",
  },
  body: {
    color: "#374047",
  },
}));

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

function VisionM() {
  const classes = useStyles();

  const dataM = {
    id: 0,
    vision: "",
    mision: "",
  };

  const [mision, setMision] = useState(dataM);

  const [values, setValues] = useState([]);

  const view = 3;
  const [number, setNumber] = useState("");

  const loadMision = () => {
    // consulta al entpoint mision para traer la misión y visión.
    axios
      .get(`${apiBaseUrl}/Mision`)
      .then((response) => {
        setMision({
          id: response.data[0].id,
          vision: response.data[0].vision,
          mision: response.data[0].mision,
        });
      })
      .catch((e) => {
        setMision({
          id: 0,
          vision: "",
          mision: "",
        });
      });
  };

  // consulta al entpoint valor para traer los valores.
  const loadSValues = useCallback(async () => {
    const respose = await axios.get(`${apiBaseUrl}/Values`);
    const values = respose.data;
    setValues(values);

    // cargar número de whatsapp de esta view
    const resp = await axios.get(`${apiBaseUrl}/Number/${view}`);
    const number = resp.data[0].telefono;
    setNumber(number);
  }, []);

  //Carga las tiendas al inicializar
  useEffect(() => {
    loadMision();
    loadSValues();
  }, [loadSValues]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Misión de la empresa
              </Typography>
              <Typography
                variant="body1"
                className={classes.body}
                gutterBottom
                align="justify"
              >
                {mision.mision}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Visión de la empresa
              </Typography>
              <Typography
                variant="body1"
                className={classes.body}
                gutterBottom
                align="justify"
              >
                {mision.vision}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Valores de la empresa
              </Typography>
              <List>
                {values.map((value) => (
                  <ListItem key={value.id}>
                    <ListItemIcon>
                      <FiberManualRecordIcon />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.body}
                      primary={value.valor}
                    />
                  </ListItem>
                ))}
              </List>
            </Col>
          </Row>
        </Col>
      </Row>

      <FloatingActionButton phone={number} />
    </>
  );
}

export default VisionM;
