import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "react-flexbox-grid";
import { DataGrid, ColDef } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    paddingBottom: 20,
    paddingTop: 20,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 5, 5),
  },
}));

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

const columns: ColDef[] = [
  { field: "id", hide: true },
  { field: "dia", headerName: "Día", width: 150 },
  { field: "horario", headerName: "Hora", width: 150 },
];

function ModalSchedules({ showModal, setModal, closeModal, idStore }) {
  const classes = useStyles();

  const [schedules, setSchedules] = useState([]);

  const loadSchedule = (id) => {
    axios.get(`${apiBaseUrl}/Schedules/${id}`).then((response) => {
      setSchedules(response.data);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      loadSchedule(idStore);
    }, 100);
  }, [idStore]);

  return (
    <>
      {showModal ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showModal}
          onClose={closeModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className={classes.paper}>
              <Row>
                <Col xs={12}>
                  <Row center="xs">
                    <Typography variant="h4" className={classes.title}>
                      Horario de atención
                    </Typography>
                  </Row>
                  {schedules.length > 0 ? (
                    <Row center="xs">
                      <div style={{ height: 400, width: "100%" }}>
                        <div style={{ display: "flex", height: "100%" }}>
                          <div style={{ flexGrow: 1 }}>
                            <DataGrid rows={schedules} columns={columns} />
                          </div>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    <Row center="xs">
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.title}
                      >
                        No hay horarios para esta tienda.
                      </Typography>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </>
  );
}

export default ModalSchedules;
