import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm, Controller } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "react-flexbox-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { green } from "@material-ui/core/colors";
import DoneAllSharpIcon from "@material-ui/icons/DoneAllSharp";
import MenuItem from "@material-ui/core/MenuItem";
import FloatingActionButton from "../components/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  title: {
    paddingBottom: 20,
    paddingTop: 20,
    color: "#374047",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  spacing: {
    paddingRight: 10,
  },
  select: {
    width: 300,
    marginTop: 10,
  },
}));

/*const ButtonC = withStyles((theme) => ({
  root: {
    color: "#EF7E25",
    backgroundColor: "#FFFEEE",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#EF7E25",
    },
  },
}))(Button);*/

const ButtonP = withStyles((theme) => ({
  root: {
    /*[theme.breakpoints.down("xs")]: {
      color: "#FFFEEE",
      backgroundColor: "#404040",
    },
    color: "#EF7E25",
    backgroundColor: "#FFFEEE",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#EF7E25",
    },*/
    color: "#FFFEEE",
    backgroundColor: "#EF7E25",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#404040",
    },
  },
}))(Button);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#EF7E25",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#EF7E25",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#EF7E25",
      },
    },
  },
})(TextField);

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

const defaultValues = {
  nombre: "",
  direccion: "",
  departamento: "",
  municipio: "",
  celular: 99999999,
  comentario: "",
};

function Wholesalers() {
  const classes = useStyles();
  const { handleSubmit, reset, setValue, control, pristine, submitting } =
    useForm({
      defaultValues,
    });

  const [departments, setDepartments] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const view = 7;
  const [number, setNumber] = useState("");

  const handleOpen = (nombre) => {
    setMessage(nombre);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // cargar departamentos
  const loadDepartments = useCallback(async () => {
    const respose = await axios.get(`${apiBaseUrl}/Departments`);
    const departments = respose.data;
    setDepartments(departments);

    // cargar número de whatsapp de esta view
    const resp = await axios.get(`${apiBaseUrl}/Number/${view}`);
    const number = resp.data[0].telefono;
    setNumber(number);
  }, []);

  // cargar municipios de un departamento
  const getMunicipalities = useCallback(async (fk_dpo) => {
    if (fk_dpo.length) {
      setMunicipalities([]);
    } else {
      const respose = await axios.get(`${apiBaseUrl}/Municipalities/${fk_dpo}`);
      const municipalities = respose.data;
      setMunicipalities(municipalities);
    }
  }, []);

  //Carga las tiendas al inicializar
  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const onSubmit = async (data) => {
    let datos = {
      nombre: data.nombre,
      direccion: data.direccion,
      departamento: data.departamento,
      municipio: data.municipio,
      celular: data.celular,
      comentario: data.comentario,
    };

    await fetch(`${apiBaseUrl}/Wholesaler`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(datos),
    })
      .then((res) => res.json())
      .then(() => {
        setValue("nombre", "");
        setValue("direccion", "");
        setValue("departamento", "");
        setValue("municipio", "");
        setValue("celular", "99999999");
        setValue("comentario", "");
        handleOpen(data.nombre);
      });

    // enviar email
    await fetch(`${apiBaseUrl}/SendEmail`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(datos),
    }).then((res) => res.json());
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Quiero ser mayorista
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>

      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="nombre"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Nombre"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "Se requiere su nombre" }}
        />
        <Controller
          name="direccion"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Dirección"
              variant="filled"
              value={value}
              onChange={onChange}
              multiline
              rows="2"
              rowsMax="4"
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "La dirección es requerida." }}
        />
        <Controller
          name="departamento"
          control={control}
          defaultValue=""
          variant="filled"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              select
              label="Departamento"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              className={classes.select}
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {departments.map((department) => (
                <MenuItem
                  value={department.nombre}
                  key={department.id}
                  onClick={() => getMunicipalities(department.id)}
                >
                  {department.nombre}
                </MenuItem>
              ))}
            </CssTextField>
          )}
          rules={{ required: "El departamento es requerido." }}
        />
        <Controller
          name="municipio"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              select
              label="Municipio"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              className={classes.select}
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {municipalities.map((municipality) => (
                <MenuItem value={municipality.nombre} key={municipality.id}>
                  {municipality.nombre}
                </MenuItem>
              ))}
            </CssTextField>
          )}
          rules={{ required: "El municipio es requerido." }}
        />
        <Controller
          name="celular"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Celular"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type="number"
            />
          )}
          rules={{
            required: "Se requiere su número de celular.",
            maxLength: 11,
            minLength: 8,
          }}
        />
        <Controller
          name="comentario"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CssTextField
              label="Comentario"
              variant="filled"
              value={value}
              onChange={onChange}
              multiline
              rows="2"
              rowsMax="4"
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "El comentario es requerido." }}
        />

        <div>
          <ButtonP
            variant="contained"
            type="button"
            disabled={pristine || submitting}
            onClick={() =>
              reset({
                nombre: "",
                direccion: "",
                departamento: "",
                municipio: "",
                celular: 99999999,
                comentario: "",
              })
            }
          >
            Cancelar
          </ButtonP>
          <ButtonP
            type="submit"
            disabled={pristine || submitting}
            variant="contained"
            color="primary"
          >
            Enviar
          </ButtonP>
        </div>
      </form>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              <DoneAllSharpIcon
                style={{ color: green[500], fontSize: 40 }}
                className={classes.spacing}
              />
              Información guardada con éxito.
            </h2>
            <div id="transition-modal-description">
              <Row>
                <Col xs={12}>
                  <Row center="xs">
                    <Col xs={10}>Gracias por su preferencia {message}</Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Fade>
      </Modal>

      <FloatingActionButton phone={number} />
    </>
  );
}

export default Wholesalers;
