import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import { getBaseUrlPath } from "./url-util";
import Home from "./views/Home";
import Store from "./views/Store";
import ContactUs from "./views/ContactUs";
import "./App.css";
import VisionM from "./views/VisionM";
import History from "./views/History";
import Questions from "./views/Questions";
import Politics from "./views/Politics";
import Wholesalers from "./views/Wholesalers";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignContent: "center",
  },
  image: {
    maxWidth: 160,
  },
  bar: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  back: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    /*[theme.breakpoints.up("xs")]: {
      color: "#FFFEEE",
      backgroundColor: "#404040",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      color: "#EF7E25",
      backgroundColor: "#FFFFFF",
    },
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#EF7E25",
    },*/
    color: "#FFFEEE",
    backgroundColor: "#EF7E25",
    "&:hover": {
      color: "#FFFEEE",
      backgroundColor: "#404040",
    },
  },
}))(Button);

function App() {
  //const rutaServ = "/"; //Pruebas
  //const basename = getBaseUrlPath(); //production
  const basename = ""; //production
  const classes = useStyles();

  return (
    <>
      <HashRouter basename={basename}>
        <AppBar position="static" color="inherit">
          <Toolbar variant="dense" className={classes.bar}>
            <Grid
              justify="space-between"
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Link href="https://www.ferrebaratillo.com/">
                  <img
                    src={`${process.env.PUBLIC_URL}/logo_sin_fondo.png`}
                    className={classes.image}
                  />
                </Link>
              </Grid>
              <Grid item>
                <ColorButton
                  className={classes.back}
                  href="https://www.ferrebaratillo.com/"
                  variant="contained"
                  color="primary"
                >
                  Volver al menú principal
                </ColorButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Container className="container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/store" component={Store} />
            {/* <Route exact path={`${rutaServ}/store`}"/store/:id" component={Store} /> */}
            <Route exact path="/contactUs" component={ContactUs} />
            <Route exact path="/vimv" component={VisionM} />
            <Route exact path="/history" component={History} />
            <Route exact path="/questions" component={Questions} />
            <Route exact path="/politics" component={Politics} />
            <Route exact path="/wholesalers" component={Wholesalers} />
            <Route render={() => <h1>Página no encontrada</h1>} />
          </Switch>
        </Container>
      </HashRouter>
    </>
  );
}

export default App;
