import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "react-flexbox-grid";

import FloatingActionButton from "../components/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  title: {
    paddingBottom: 20,
    paddingTop: 20,
    color: "#374047",
  },
  body: {
    color: "#374047",
  },
}));

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

function History() {
  const classes = useStyles();

  const dataH = {
    id: 0,
    history: "",
  };

  const [history, setHistory] = useState(dataH);

  const view = 4;
  const [number, setNumber] = useState("");

  const loadHistory = useCallback(async () => {
    axios
      .get(`${apiBaseUrl}/Mision`)
      .then((response) => {
        setHistory({
          id: response.data[0].id,
          history: response.data[0].historia,
        });
      })
      .catch((e) => {
        setHistory({
          id: 0,
          history: "",
        });
      });

    // cargar número de whatsapp de esta view
    const resp = await axios.get(`${apiBaseUrl}/Number/${view}`);
    const number = resp.data[0].telefono;
    setNumber(number);
  }, []);

  //Carga la historia al inicializar
  useEffect(() => {
    loadHistory();
  }, [loadHistory]);

  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Historia de la empresa
              </Typography>
              <Typography
                className={classes.body}
                component={"div"}
                variant={"body1"}
                gutterBottom
                align="justify"
              >
                {renderHTML(history.history)}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>

      <FloatingActionButton phone={number} />
    </>
  );
}

export default History;
