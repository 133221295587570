import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";

import FloatingActionButton from "../components/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    color: "#374047",
  },
  text: {
    color: "#374047",
  },
}));

const apiBaseUrl = "https://apiauxecommerce.ferrebaratillo.com/api";
//const apiBaseUrl = "http://localhost:63219/api";

function Politics() {
  const classes = useStyles();

  const [politics, setPolitics] = useState([]);

  const view = 6;
  const [number, setNumber] = useState("");

  const loadPolitics = useCallback(async () => {
    const respose = await axios.get(`${apiBaseUrl}/Politics`);
    const politics = respose.data;
    setPolitics(politics);

    // cargar número de whatsapp de esta view
    const resp = await axios.get(`${apiBaseUrl}/Number/${view}`);
    const number = resp.data[0].telefono;
    setNumber(number);
  }, []);

  //Carga las tiendas al inicializar
  useEffect(() => {
    loadPolitics();
  }, [loadPolitics]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row center="xs">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Typography variant="h4" className={classes.title}>
                Politicas de cambio y devoluciones
              </Typography>

              <div className={classes.demo}>
                <List>
                  {politics.map((politic) => (
                    <ListItem key={politic.id}>
                      <ListItemAvatar>
                        <Avatar>
                          <DescriptionIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        className={classes.text}
                        primary={politic.nombre}
                        secondary={politic.descripcion}
                        style={{ marginRight: 20 }}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          href={`${apiBaseUrl}/Uploads/${politic.documento}`}
                        >
                          <GetAppIcon style={{ fontSize: 40 }} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <FloatingActionButton phone={number} />
    </>
  );
}

export default Politics;
